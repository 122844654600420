import {
  GET_CURRENT_USER_FAILURE,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
} from 'constants/action-types/user/currentUser';
import apiAction from 'helpers/apiAction';

const onSuccess = (payload, callback) => dispatch => {
  dispatch({
    type: GET_CURRENT_USER_SUCCESS,
    payload,
  });
  callback?.(payload?.data?.organizations);
};

const onFailure = payload => dispatch => {
  dispatch({
    type: GET_CURRENT_USER_FAILURE,
    payload,
  });
};

export default callback => dispatch => {
  const userToken = localStorage.getItem('token');
  if (userToken) {
    return dispatch(
      apiAction({
        url: '/user',
        method: 'GET',
        data: true,
        API_REQUEST_START: GET_CURRENT_USER_START,
        API_REQUEST_SUCCESS: GET_CURRENT_USER_SUCCESS,
        API_REQUEST_FAILURE: GET_CURRENT_USER_FAILURE,
        onSuccess: payload => onSuccess(payload, callback)(dispatch),
        onFailure: error => {
          onFailure(error)(dispatch);
        },
        onEnd: () => false,
      }),
    );
  }
  return null;
};

export const resetUserAction = () => dispatch => {
  dispatch({
    type: GET_CURRENT_USER_SUCCESS,
    payload: {},
  });
};
