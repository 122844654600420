/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import './index.scss';
import { Table, Popup, Icon, Dropdown } from 'semantic-ui-react';
import formatNumber from 'helpers/formatNumber';
import capitalize from 'utils/capitalize';
import Thumbnail from 'components/common/Thumbnail';
import RequestPopupContent from './RequestPopupContent';
import LoanApprovalModal from './ApprovalModal';
import LoanAmendModal from './AmendModal';

const options = [
  {
    key: 'approve',
    text: 'Approve',
    color: '#15AE70DE',
    value: 'approve',
  },
  { key: 'amend', text: 'Amend', color: '#0F83F2', value: 'amend' },
];

const LoanRequestTable = ({ items }) => {
  const [open, setOpen] = useState(null);
  const { push } = useHistory();
  const [selectedRequest, setSelectedRequest] = useState(null);

  const onOptionClicked = (option, request) => {
    setOpen(option);
    setSelectedRequest(request);
  };

  return (
    <>
      <LoanApprovalModal
        open={open === 'approve'}
        setOpen={setOpen}
        request={selectedRequest}
      />
      <LoanAmendModal
        open={open === 'amend'}
        setOpen={setOpen}
        request={selectedRequest}
      />
      <div className="list-loan-requests__items__table">
        <Table basic="very" size="small" textAlign="left">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="">Group</Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Requested by
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Requested amount{' '}
                  <span style={{ fontSize: 10, fontWeight: 300 }}>
                    (RWF)
                  </span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Disbursed amount{' '}
                  <span style={{ fontSize: 10, fontWeight: 300 }}>
                    (RWF)
                  </span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Repayment Days
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Repayment Installments
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Interest on Loan{' '}
                  <span style={{ fontSize: 10, fontWeight: 300 }}>
                    (RWF)
                  </span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Repaid Loan{' '}
                  <span style={{ fontSize: 10, fontWeight: 300 }}>
                    (RWF)
                  </span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                <span>
                  Outstanding Loan{' '}
                  <span style={{ fontSize: 10, fontWeight: 300 }}>
                    (RWF)
                  </span>
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Loan reason
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data">
                Expiration Date
              </Table.HeaderCell>
              <Table.HeaderCell className="table-data" width="1" />
            </Table.Row>
          </Table.Header>

          <Table.Body
            className={`list-loan-requests__items__table__tbody wallet__transactions--table-body ${true &&
              'lg-tbody'}`}
          >
            {items.map(request => {
              return (
                <Table.Row
                  key={request.id}
                  className="table-row cursor"
                >
                  <Table.Cell
                    className="wallet__transactions--originator"
                    onClick={() => {
                      const profileInfo =
                        request?.group?.loan_profile_monthly?.[0]
                          ?.loan_profile;
                      push(
                        `/profiles/${profileInfo.id}/requests/${request?.id}`,
                      );
                    }}
                  >
                    <Thumbnail name={request?.group?.name} />
                    <div>
                      <span>{request?.group?.name}</span>
                      <div style={{ fontSize: 10 }}>
                        <b>Profile: </b>
                        <span>
                          {request?.group?.loan_profile_monthly?.[0]
                            ?.loan_profile?.name || 'No profile'}
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {`${request?.user?.first_name} ${request?.user?.last_name}`}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.amount)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loan_disbursed_amount)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.repayment_days)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.repayment_installments)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_interest)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loan_paid_amount)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {formatNumber(request.loans_balance)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {capitalize(request.purpose)}
                  </Table.Cell>
                  <Table.Cell className="table-data">
                    {moment(request.expiration_date).format(
                      'MMM DD, YYYY',
                    )}
                  </Table.Cell>
                  <Table.Cell className="table-data" width="1">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span className="more-info">
                        <Popup
                          trigger={
                            <div style={{ color: '#2185D0' }}>
                              <Icon
                                name="info circle"
                                color="#2185D0"
                                className="info-icon"
                              />
                              <u>info</u>
                            </div>
                          }
                          content={
                            <RequestPopupContent request={request} />
                          }
                          hoverable
                          position="left center"
                          on="click"
                          pinned
                        />
                      </span>
                      <Dropdown
                        icon={null}
                        direction="left"
                        options={options}
                        onChange={(e, { value }) => {
                          console.log(value);
                        }}
                        trigger={
                          <div className="action-icon">
                            <Icon name="ellipsis vertical" />
                          </div>
                        }
                      >
                        <Dropdown.Menu>
                          <>
                            {request?.status === 'approved' ||
                              options.map(option => (
                                <Dropdown.Item
                                  style={{ color: option.color }}
                                  key={option.key}
                                  onClick={() => {
                                    onOptionClicked(
                                      option.value,
                                      request,
                                    );
                                  }}
                                >
                                  {option.text}
                                </Dropdown.Item>
                              ))}

                            <Dropdown.Item
                              onClick={() => {
                                const profileInfo =
                                  request?.group
                                    ?.loan_profile_monthly?.[0]
                                    ?.loan_profile;
                                push(
                                  `/profiles/${profileInfo.id}/requests/${request?.id}`,
                                );
                              }}
                            >
                              View
                            </Dropdown.Item>
                          </>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

LoanRequestTable.defaultProps = {
  items: [],
};

LoanRequestTable.propTypes = {
  items: PropTypes.array,
};

export default LoanRequestTable;
