// @ts-check
import { chain } from 'lodash';

/**
 * @brief ## get duplicates from an array
 * @author Hadad
 * @param {Array} array
 * @returns {Array}
 */

export default array =>
  chain(array) // [1, 2, 1]
    .groupBy() //  ==> { '1': [ 1, 1 ], '2': [ 2 ] }
    .pickBy(element => element.length > 1) // pick only ones with more than 1 element ==>  { '1': [ 1, 1 ] }
    .keys() // then we get the keys aka those element ==> ['1']
    .value(); // get the value from chain
