/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withRouter,
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Image, Header, Button, Dropdown } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import HomeIcon from 'assets/images/home_icon.svg';
import './AppHeader.scss';
import getSingleOrganization from 'redux/actions/organizations/getSingleOrganization';
import { isSuperAdmin, isSSA } from 'helpers/checkRoles';
import capitalize from 'utils/capitalize';
import urlIdParser from 'helpers/urlIdParser';
import isParentOrganization, {
  isGlobalOrganization,
} from 'helpers/isParentOrganization';
import getOrganizations from 'redux/actions/organizations/listOrganizations';
import getProjects from 'redux/actions/projects/listProjects';
import getCurrentOrg from 'helpers/getCurrentOrg';
import {
  isBusinessOrganization,
  isOrgNGO,
} from 'helpers/organizationType';
import getProfiles from 'redux/actions/profiles/listProjects';

const AppHeader = () => {
  const isCurrentOrgParent = isParentOrganization();
  const isCurrentOrgGlobal = isGlobalOrganization();
  const currentUserOrganization = getCurrentOrg();
  const isCurrentOrgBusiness = isBusinessOrganization();
  const isCurrentOrgNGO = isOrgNGO();

  const {
    organizationsList: { items: organizationsItems = [] },
    projectsList: { items: projectsItems = [] },
    user: {
      currentUser: {
        details: { roles = [] },
      },
    },
  } = useSelector(
    ({
      organizations: { list: organizationsList },
      projects: { list: projectsList },
      user,
    }) => ({ organizationsList, projectsList, user }),
  );

  const { items: profiles } = useSelector(
    ({ profiles: { profileList } }) => profileList,
  );

  const options = () => {
    let currentOptions = isCurrentOrgParent
      ? organizationsItems
      : isCurrentOrgBusiness
      ? profiles
      : projectsItems;

    currentOptions = currentOptions.sort((a, b) =>
      capitalize(a.name) > capitalize(b.name)
        ? 1
        : capitalize(b.name) > capitalize(a.name)
        ? -1
        : 0,
    );

    return currentOptions.map(
      ({ name, id, organization_id: organizationId }) => ({
        text: name,
        key: name,
        value: JSON.stringify({ id, organizationId }),
      }),
    );
  };

  const { push } = useHistory();

  const [currentOptionName, setCurrentOptionName] = useState();

  const handleOptionsChange = (e, { value }) => {
    const { id, organizationId } = JSON.parse(value);
    if (isCurrentOrgParent) {
      push(`/organizations/${id}/overview`);
    } else if (isCurrentOrgBusiness) {
      push(`/profiles/${id}/overview`);
    } else if (isCurrentOrgNGO && !isCurrentOrgParent) {
      push(
        `/projects/${id}/organizations/${organizationId}/overview`,
      );
    }

    const currentOption = options().find(
      item => Number(item.value.id) === id,
    );
    if (currentOption) {
      setCurrentOptionName(currentOption.text);
    }
  };

  const dropdownTitle =
    isCurrentOrgBusiness && !isCurrentOrgParent
      ? 'profile'
      : isCurrentOrgParent
      ? 'organization'
      : 'project';

  let placeholder = `Select ${capitalize(dropdownTitle)}`;

  const { pathname = '' } = useLocation();

  const parsedUrl = urlIdParser(pathname);

  const isOnProjectOrOrgDetails = () =>
    (parsedUrl.organizations || parsedUrl.projects) !== undefined;

  if (parsedUrl.projects) {
    (dropdownTitle === 'projects'
      ? projectsItems
      : organizationsItems
    ).forEach(item => {
      if (String(item.id) === String(parsedUrl.projects)) {
        placeholder = item.name;
      }
    });
  }

  const {
    getSingleOrganization: {
      loading: loadingOrganizations,
      data: { organization: currentOrganization } = {},
    },
  } = useSelector(({ organizations }) => organizations);

  const organizationName = currentOrganization?.name;

  const {
    overview: { project: { name: projectName } = {} } = {},
    loading: loadingProjects,
  } = useSelector(
    ({ projects: { currentProject } }) => currentProject,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (parsedUrl.organizations)
      getSingleOrganization(parsedUrl.organizations)(dispatch);
    if (
      /(projects|organizations)/.test(pathname) &&
      (!loadingOrganizations || !loadingProjects)
    ) {
      setCurrentOptionName(
        isCurrentOrgParent ? organizationName : projectName,
      );
    } else {
      setCurrentOptionName(null);
    }
  }, [organizationName, projectName, pathname]);

  useEffect(() => {
    if (!isEmpty(currentUserOrganization)) {
      if (isCurrentOrgParent) getOrganizations()(dispatch);
      else getProjects(currentUserOrganization?.id)(dispatch);
    }
  }, [currentUserOrganization, isCurrentOrgParent]);

  useEffect(() => {
    if (!isEmpty(currentUserOrganization))
      getProfiles(currentUserOrganization?.id)(dispatch);
  }, [currentUserOrganization]);

  useEffect(() => {
    const profileId = parsedUrl.profiles;

    const currentOption = options().find(item => {
      return (
        Number(JSON.parse(item?.value)?.id) === Number(profileId)
      );
    });

    if (currentOption) {
      setCurrentOptionName(currentOption.text);
    }
  }, []);

  return (
    <div id="titlebar">
      <NavLink
        className="home-link"
        activeClassName="active"
        exact
        to="/"
      >
        <Image src={HomeIcon} />
      </NavLink>
      <div id="top-selector">
        {isOnProjectOrOrgDetails() ? (
          <Header as="h1">
            <Dropdown
              placeholder={currentOptionName || placeholder}
              scrolling
              search
              options={options()}
              selectOnBlur={false}
              onChange={handleOptionsChange}
            />
          </Header>
        ) : (
          <Header as="h2">
            <Dropdown
              placeholder={currentOptionName || placeholder}
              scrolling
              search
              options={options()}
              selectOnBlur={false}
              onChange={handleOptionsChange}
            />
          </Header>
        )}
      </div>
      <div className="flex-1" />
      {isBusinessOrganization() && !isCurrentOrgParent ? (
        <Button
          as={NavLink}
          to="/profiles/new"
          activeClassName="disabled"
          exact
          primary
          content="New Profile"
          labelPosition="right"
          icon="add"
        />
      ) : (
        !(isSSA(roles) && isCurrentOrgGlobal) && (
          <Button
            as={NavLink}
            to="/create-project"
            activeClassName="disabled"
            exact
            primary
            content="New Project"
            labelPosition="right"
            icon="add"
          />
        )
      )}
      {isSuperAdmin(roles) && isCurrentOrgParent && (
        <Button
          as={NavLink}
          to="/create-organization"
          activeClassName="disabled"
          exact
          primary
          content="New Organization"
          labelPosition="right"
          icon="add"
        />
      )}
    </div>
  );
};

AppHeader.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(AppHeader);
