/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, pullAllBy } from 'lodash';
import Toolbar from 'components/common/Toolbar';
import {
  toDropDownOptions,
  walletTransactionOptions,
} from 'utils/dropdownOptions';
import groupTypes from 'constants/groupTypes';
import addBill from 'redux/actions/settings/addBill';
import {
  billNameTypes,
  billRestrictCategories,
  billsRequiredFields,
  chargeFromWallet,
  chargeOnTransactionTypes,
  chargePerCategory,
  chargeTypes,
  computeFromTransactionField,
  computeOnTransactionTypes,
  frequencyTypes,
  getChargeFrom,
  groupsPurpose,
  providerTypes,
} from 'constants/billingTypes';
import ConfirmPinModal from 'components/common/modals/ConfirmPinModal';
import validateFields from 'helpers/validators/validateFields';
import isFieldRequired from 'helpers/validators/isFieldRequired';
import { getAllCategories } from 'redux/actions/settings/getCategoryFeatures';
import formatBillingData from 'helpers/shapeFormatter/billingData';

const AddBill = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [form, setForm] = useState({
    status: 'active',
    notification: { rw: null, en: null },
  });
  const [sendNotification, setSendNotification] = useState(true);
  const [displayFieldError, setFieldError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userPin, setUserPin] = useState(null);
  const [categories, setCategories] = useState([]);

  const { loading, error } = useSelector(
    ({ settings: { addBill } }) => addBill,
  );

  const { loading: categoriesLoading, data } = useSelector(
    ({ settings: { categoryFeatures } }) => categoryFeatures,
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      setCategories(
        pullAllBy(data, billRestrictCategories, 'purpose'),
      );
    }
  }, [data]);

  const handleChange = (e, { name, value }) => {
    setFieldError(false);
    if (
      name === billNameTypes.charge_from_wallet &&
      value === 'individual_wallet'
    ) {
      getAllCategories({
        source: value,
        type: form?.compute_on_transaction_type,
      })(dispatch);

      return setForm({
        ...form,
        [name]: value,
        charge_frequency: 'instant',
        wallet_provider: providerTypes[0],
      });
    }

    if (
      name === billNameTypes.charge_per_category &&
      value === 'group_member'
    ) {
      return setForm({
        ...form,
        [name]: value,
        charge_frequency: 'instant',
      });
    }

    if (
      name === billNameTypes.charge_on_transaction_type &&
      value === 'credit'
    ) {
      return setForm({
        ...form,
        [name]: value,
        get_charge_from: 'wallet',
      });
    }

    if (
      name === billNameTypes.charge_frequency &&
      value === 'monthly'
    ) {
      return setForm({
        ...form,
        [name]: value,
        charge_on_transaction_type: undefined,
      });
    }

    if (name === billNameTypes.amount_maximum && !value)
      return setForm({ ...form, [name]: null });

    if (name === billNameTypes.en || name === billNameTypes.rw) {
      if (name === billNameTypes.en) setFieldError(false);
      if (name === billNameTypes.rw) setFieldError(false);
      return setForm({
        ...form,
        notification: { ...form.notification, [name]: value },
      });
    }

    if (name === billNameTypes.charge_from_wallet)
      getAllCategories({
        source: value,
        type: form?.compute_on_transaction_type,
      })(dispatch);

    if (name === billNameTypes.compute_on_transaction_type)
      getAllCategories({
        source: form?.charge_from_wallet,
        type: value,
      })(dispatch);

    setForm({ ...form, [name]: value });
  };

  const checkFields = () => {
    if (
      validateFields(
        form?.charge_from_wallet === 'individual_wallet'
          ? [...billsRequiredFields, 'charge_on_transaction_type']
          : form?.charge_from_wallet !== 'individual_wallet' &&
            form?.charge_frequency === 'instant'
          ? [
              ...billsRequiredFields,
              'charge_on_transaction_type',
              'charge_from_wallet',
            ]
          : [...billsRequiredFields, 'charge_from_wallet'],
        form,
      ) && sendNotification
        ? form?.notification?.en && form?.notification?.rw
        : true
    ) {
      setFieldError(false);
      return setOpenModal(true);
    }
    return setFieldError(true);
  };

  const handleSubmit = () => {
    const data = formatBillingData(
      form,
      getChargeFrom,
      providerTypes,
      frequencyTypes,
      userPin,
    );

    addBill({
      ...data,
      send_notification: sendNotification,
      notification: sendNotification ? data.notification : undefined,
    })(dispatch, push);
  };

  return (
    <div className="billings__add">
      <Toolbar
        header={{
          title: 'Add bill',
          navigation: true,
        }}
      />
      <Form loading={loading}>
        <Form.Input
          placeholder="Name"
          label="Bill Name"
          fluid
          name={billNameTypes.name}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.name,
            'Bill Name',
          )}
        />
        <Form.Group widths="equal" className="group-items">
          <Form.Dropdown
            fluid
            selection
            label="Charge from wallet"
            placeholder="-- Select --"
            name={billNameTypes.charge_from_wallet}
            options={toDropDownOptions(chargeFromWallet)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.charge_from_wallet,
              'Charge from wallet',
            )}
          />

          {form?.charge_from_wallet === 'individual_wallet' ||
          form?.charge_per_category === 'group_member' ? (
            <Form.Dropdown
              fluid
              disabled
              selection
              label="Frequency"
              placeholder="-- Select --"
              name={billNameTypes.charge_frequency}
              onChange={handleChange}
              value={frequencyTypes?.[0]}
              options={toDropDownOptions(['instant'])}
              error={isFieldRequired(
                displayFieldError,
                !form?.charge_frequency,
                'Frequency',
              )}
            />
          ) : (
            <Form.Dropdown
              fluid
              selection
              label="Frequency"
              placeholder="-- Select --"
              name={billNameTypes.charge_frequency}
              onChange={handleChange}
              options={toDropDownOptions(frequencyTypes)}
              error={isFieldRequired(
                displayFieldError,
                !form?.charge_frequency,
                'Frequency',
              )}
            />
          )}
        </Form.Group>

        <Form.Group widths="equal" className="group-items">
          {form?.charge_on_transaction_type === 'credit' ? (
            <Form.Dropdown
              fluid
              disabled
              selection
              label="Get charge from"
              placeholder="-- Select --"
              name={billNameTypes.get_charge_from}
              options={toDropDownOptions(['wallet'])}
              value={getChargeFrom?.[0]}
              onChange={handleChange}
              error={isFieldRequired(
                displayFieldError,
                !form?.get_charge_from,
                'Get charge from',
              )}
            />
          ) : (
            <Form.Dropdown
              fluid
              selection
              label="Get charge from"
              placeholder="-- Select --"
              name={billNameTypes.get_charge_from}
              options={toDropDownOptions(getChargeFrom)}
              onChange={handleChange}
              error={isFieldRequired(
                displayFieldError,
                !form?.get_charge_from,
                'Get charge from',
              )}
            />
          )}

          <Form.Dropdown
            fluid
            selection
            label="Compute on transaction type"
            placeholder="-- Select --"
            name={billNameTypes.compute_on_transaction_type}
            options={toDropDownOptions(computeOnTransactionTypes)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.compute_on_transaction_type,
              'Compute on transaction type',
            )}
          />
        </Form.Group>

        <Form.Group widths="equal" className="group-items">
          <Form.Dropdown
            fluid
            selection
            label="Compute on transaction field"
            placeholder="-- Select --"
            name={billNameTypes.compute_from_transaction_field}
            options={toDropDownOptions(computeFromTransactionField)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.compute_from_transaction_field,
              'Compute on transaction field',
            )}
          />
          {form?.charge_frequency === 'instant' ? (
            <Form.Dropdown
              fluid
              selection
              label={`Charge on transaction type ${
                form?.charge_frequency === 'instant' ? '' : '(Opt)'
              }`}
              placeholder="-- Select --"
              name={billNameTypes.charge_on_transaction_type}
              options={toDropDownOptions(chargeOnTransactionTypes)}
              onChange={handleChange}
              error={
                form?.charge_frequency === 'instant' &&
                isFieldRequired(
                  displayFieldError,
                  !form?.charge_on_transaction_type,
                  'Charge on transaction type',
                )
              }
            />
          ) : null}
        </Form.Group>

        {form?.charge_from_wallet === 'individual_wallet' || (
          <Form.Dropdown
            fluid
            selection
            label="Charge per category"
            placeholder="-- Select --"
            name={billNameTypes.charge_per_category}
            options={toDropDownOptions(chargePerCategory)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.charge_per_category,
              'Charge per category',
            )}
          />
        )}

        <Form.Dropdown
          fluid
          multiple
          selection
          label={`Group type ${
            form?.charge_from_wallet === 'group_wallet'
              ? ''
              : '(Optional)'
          }`}
          placeholder="-- Select --"
          name={billNameTypes.groups_types}
          options={toDropDownOptions(groupTypes)}
          onChange={handleChange}
          error={
            form?.charge_from_wallet === 'group_wallet' &&
            isFieldRequired(
              displayFieldError,
              !form?.groups_types,
              'Group type',
            )
          }
        />

        <Form.Dropdown
          fluid
          multiple
          selection
          label={`Group purpose ${
            form?.groups_types ? '' : '(Optional)'
          }`}
          placeholder="-- Select --"
          name={billNameTypes.groups_purposes}
          options={toDropDownOptions(groupsPurpose)}
          onChange={handleChange}
          error={
            form?.groups_types &&
            isFieldRequired(
              displayFieldError,
              !form?.groups_purposes,
              'Group purpose',
            )
          }
        />

        {form?.charge_from_wallet === 'individual_wallet' ? (
          <Form.Dropdown
            fluid
            selection
            disabled
            label="Wallet provider"
            placeholder="-- Select --"
            name={billNameTypes.wallet_provider}
            options={toDropDownOptions(['Access_bank'], true)}
            value={providerTypes?.[0]}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.wallet_provider,
              'Wallet provider',
            )}
          />
        ) : (
          <Form.Dropdown
            fluid
            selection
            label="Wallet provider"
            placeholder="-- Select --"
            name={billNameTypes.wallet_provider}
            options={toDropDownOptions(providerTypes, true)}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.wallet_provider,
              'Wallet provider',
            )}
          />
        )}

        <Form.Dropdown
          fluid
          multiple
          selection
          loading={categoriesLoading}
          label="Transaction categories"
          placeholder="-- Select --"
          name={billNameTypes.transaction_purposes}
          options={walletTransactionOptions(categories)}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.transaction_purposes,
            'Transaction categories',
          )}
        />

        <label>Range (Rwf)</label>
        <Form.Group widths="equal" className="group-items">
          <span className="label-from">From</span>
          <Form.Input
            placeholder="Min amount"
            fluid
            name={billNameTypes.amount_minimum}
            onChange={handleChange}
            error={isFieldRequired(
              displayFieldError,
              !form?.amount_minimum,
              'Minimum amount',
            )}
          />
          <span>To</span>
          <Form.Input
            placeholder="Max amount"
            fluid
            name={billNameTypes.amount_maximum}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Dropdown
          fluid
          selection
          label="Fees type"
          placeholder="-- Select --"
          name={billNameTypes.charge_type}
          options={toDropDownOptions(chargeTypes)}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.charge_type,
            'Fees type',
          )}
        />

        <Form.Input
          placeholder={
            form?.charge_type === 'percentage'
              ? 'Fees Percentage (%)'
              : 'Fees Amount (Rwf)'
          }
          label={
            form?.charge_type === 'percentage'
              ? 'Fees Percentage (%)'
              : 'Fees Amount (Rwf)'
          }
          fluid
          name={billNameTypes.charge}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.charge,
            'Fees',
          )}
        />

        <Form.TextArea
          fluid
          label="Description"
          placeholder="Description"
          name={billNameTypes.description}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.description,
            'Description',
          )}
        />
        <div className="message-label-group">
          <label>Message to users</label>
          <Radio
            toggle
            label=""
            name="send_message"
            checked={sendNotification}
            defaultChecked
            onChange={(e, { checked }) =>
              setSendNotification(checked)
            }
          />
        </div>
        {sendNotification && (
          <Form.Group widths="equal" className="group-items">
            <Form.TextArea
              fluid
              name={billNameTypes.en}
              placeholder="Enter the message in English"
              onChange={handleChange}
              error={
                !form?.notification?.en &&
                isFieldRequired(
                  displayFieldError,
                  !form?.notification?.en,
                  'English message',
                )
              }
            />
            <Form.TextArea
              fluid
              name={billNameTypes.rw}
              placeholder="Enter the message in Kinyarwanda"
              onChange={handleChange}
              error={
                !form?.notification?.rw &&
                isFieldRequired(
                  displayFieldError,
                  !form?.notification?.rw,
                  'Kinyarwanda message',
                )
              }
            />
          </Form.Group>
        )}
        <Form.Input
          fluid
          type="date"
          label="When does it start applying?"
          name={billNameTypes.effective_date}
          min={moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')}
          onChange={handleChange}
          error={isFieldRequired(
            displayFieldError,
            !form?.effective_date,
            'Effective date',
          )}
        />
        <Form.Group />
        <Form.Group widths="equal">
          <Form.Field>
            {form?.charge_from_wallet === 'individual_wallet' ? (
              <Radio
                toggle
                label="Status"
                name={billNameTypes.status}
                checked={false}
                defaultChecked={form?.status === 'active'}
                onChange={(e, { checked }) =>
                  handleChange(_, {
                    name: billNameTypes.status,
                    value: checked ? 'active' : 'inactive',
                  })
                }
              />
            ) : (
              <Radio
                toggle
                label="Status"
                name={billNameTypes.status}
                defaultChecked
                onChange={(e, { checked }) =>
                  handleChange(_, {
                    name: billNameTypes.status,
                    value: checked ? 'active' : 'inactive',
                  })
                }
              />
            )}
          </Form.Field>
          <Form.Button
            primary
            name="addButton"
            floated="right"
            content="Add"
            onClick={checkFields}
          />
        </Form.Group>
      </Form>
      <ConfirmPinModal
        content={`Are you sure you want to add the bill "${form?.name}"?`}
        openModal={openModal}
        setOpenModal={setOpenModal}
        userPin={userPin}
        setUserPin={setUserPin}
        hasComplexSubmitAction
        handleSubmit={handleSubmit}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default AddBill;
