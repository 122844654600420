import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import './SearchInput.scss';
import { debounce, omitBy } from 'lodash';
import { formatSearchParameter } from 'helpers/formatUrlParameter';
import removeEmptyProperties from 'helpers/removeEmptyProperties';
import { transactionSearchByOptions } from 'constants/searchByOptions';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;
const SEARCH_INTERVAL = 1;

const SearchInput = ({
  onSearch,
  updateUrl,
  onKeyDown,
  searchBy,
}) => {
  let urlQueries = '';
  const { push } = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const [input, setInput] = useState(queries.search || '');
  const [timer, setTimer] = useState(null);

  const debounceSearch = debounce((search, urlQueries) => {
    onSearch(
      removeEmptyProperties({ ...queries, ...search, urlQueries }),
    );
  }, SEARCH_INTERVAL);

  const handleClear = () => {
    if (
      queries.search ||
      transactionSearchByOptions.includes(searchBy)
    ) {
      setInput('');
      push(
        formatSearchParameter({
          ...omitBy(queries, [searchBy, 'search']),
        }),
      );
      debounceSearch('', '');
    }
  };

  const updateUrlQueries = (search = '') => {
    let searchParam = 'search';
    if (updateUrl && queries) {
      Object.keys(queries || {}).forEach(key => {
        if (
          queries?.[key] &&
          key !== 'search' &&
          !transactionSearchByOptions.includes(searchBy)
        ) {
          urlQueries += `&${key}=${queries[key]}`;
        }
      });

      searchParam = searchBy || 'search';

      push(`?${searchParam}=${search}${urlQueries}`);
    }
    debounceSearch(
      {
        [searchParam]: search,
      },
      urlQueries,
    );
  };

  useEffect(() => {
    if (input) {
      updateUrlQueries(input);
    }
  }, []);

  const triggerChange = newValue => {
    if (onKeyDown) {
      onKeyDown({ target: { value: input } });
    }
    updateUrlQueries(newValue);
  };

  const handleChange = e => {
    clearTimeout(timer);
    const newInput = e.target.value;
    setTimer(
      setTimeout(() => triggerChange(newInput), WAIT_INTERVAL),
    );
    setInput(newInput);
  };

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      triggerChange(e.target.value);
    }
  };

  return (
    <Input
      value={input || ''}
      className="toolbar-search-input"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      icon={{
        name: input ? 'close' : 'search',
        className: 'clear-input',
        onClick: handleClear,
      }}
      iconPosition={input ? 'right' : 'left'}
      placeholder="Search"
    />
  );
};

SearchInput.defaultProps = {
  updateUrl: true,
  onSearch: value => value,
  searchBy: '',
};

SearchInput.propTypes = {
  onSearch: PropTypes.func,
  updateUrl: PropTypes.bool,
  onKeyDown: PropTypes.func.isRequired,
  searchBy: PropTypes.string,
};

export default SearchInput;
