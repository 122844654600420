/* eslint-disable no-restricted-globals */
/* eslint-disable valid-typeof */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import { capitalize, compact, reduce, some } from 'lodash';
import { Label, List, Message, Table } from 'semantic-ui-react';
import cancelImage from 'assets/images/cancel.svg';
import successImage from 'assets/images/success.svg';
import skippedImage from 'assets/images/info-circle.svg';
import errorImage from 'assets/images/error.svg';
import folderImage from 'assets/images/file.svg';
import xlsFileExtension from 'assets/images/xlsFileExtension.svg';
import xlsxFileExtension from 'assets/images/xlsxFileExtension.svg';
import formatBulkData from 'helpers/formatBulkData';
import bulkCreate from 'redux/actions/organizations/bulkCreate';
import UploadProgress from 'components/common/UploadProgress';
import {
  DATA_COLLECTION_TEMPLATE,
  DATA_COMPILATION_TEMPLATE,
} from 'constants/Templates';
import validateBulkData from 'helpers/validateBulkData';
import sheetSchemas from './sheetSchemas';

const UploadSuccess = ({
  data: {
    added_agents_count: addedAgentsCount,
    added_members_count: addedMembersCount,
    added_groups_count: addedGroupsCount,
    duplicates,
  },
}) => (
  <div className="upload-box">
    <div className="top">
      <div>
        <span className="action">Complete</span>
        <img src={successImage} alt="" />
      </div>

      <Table celled fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Added members</Table.HeaderCell>
            <Table.HeaderCell>Added agents</Table.HeaderCell>
            <Table.HeaderCell>Added groups</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{addedMembersCount}</Table.Cell>
            <Table.Cell>{addedAgentsCount}</Table.Cell>
            <Table.Cell>{addedGroupsCount}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
    <div className="top">
      <div className="heading">
        <span className="action">Skipped/Duplicated</span>
        <img src={skippedImage} alt="" />
      </div>

      <Table celled fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Skipped members</Table.HeaderCell>
            <Table.HeaderCell>Skipped agents</Table.HeaderCell>
            <Table.HeaderCell>Skipped groups</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{duplicates?.members?.count}</Table.Cell>
            <Table.Cell>{duplicates?.agents?.count}</Table.Cell>
            <Table.Cell>{duplicates?.groups?.count}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  </div>
);

const UploadError = ({
  error: {
    downloadable,
    errors: frontendErrors,
    error: backendErrors,
    message,
  } = {},
  setState,
}) => {
  const errors = frontendErrors || backendErrors?.errors;
  return (
    <div className="upload-box">
      <div className="top error">
        <div className="top-error">
          <img src={errorImage} alt="" />
          <span>Error</span>
        </div>
        <button type="button" onClick={() => setState()}>
          <img src={cancelImage} className="cancel-image" alt="" />
        </button>
      </div>
      <div className="error-message">
        <span className="error-message__summary">{message}</span>
        {errors &&
          errors?.map(({ message, path, content, data }) => (
            <>
              <span>
                {message?.includes('__sp') ? (
                  <ul>
                    {compact(message.split('__sp')).map(separateMsg =>
                      separateMsg?.includes('__sub_sp') ? (
                        <>
                          <li>
                            {separateMsg.split('__sub_sp')?.[0]}
                          </li>
                          <ul>
                            {compact(
                              separateMsg.split('__sub_sp').slice(1),
                            ).map(subMessage => (
                              <li>{subMessage}</li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <li>{separateMsg}</li>
                      ),
                    )}
                  </ul>
                ) : (
                  message
                )}
              </span>
              {path && path[0] ? (
                <span> Field: {path[0]}</span>
              ) : null}
              {content && content.key ? (
                <span>, Key: {content.key}</span>
              ) : null}
              {content && content.value ? (
                <span>, Value: {content.value}</span>
              ) : null}
              {data && (
                <div>
                  <br />
                  Data: {data}
                </div>
              )}
            </>
          ))}
        <br />
        {downloadable && (
          <>
            Download your file <span className="link"> here</span>
          </>
        )}
      </div>
    </div>
  );
};

const BulkCreate = () => {
  const [error, setError] = useState({});
  const [state, setState] = useState();
  const { organizationId, projectId, tabOption } = useParams();
  const dispatch = useDispatch();
  const { data, error: apiError } = useSelector(
    ({ organizations: { bulkCreate } }) => bulkCreate,
  );

  const { overview: projectOverviewData } = useSelector(
    ({ projects: { currentProject } }) => currentProject,
  );

  const [
    ,
    uploadLastNumber,
  ] = projectOverviewData?.group_last_upload_number?.split('-');

  useEffect(() => {
    if (apiError) {
      setError(apiError);
      setState('error');
    } else {
      setError({});
      setState(null);
    }
  }, [dispatch, apiError]);

  const handleSubmit = data => {
    if (data.length > 1000) {
      setState('error');
      setError({
        errors: [
          {
            message:
              'The payload is too large, the limit is 1000 members',
          },
        ],
      });
      return;
    }

    bulkCreate(
      organizationId,
      projectId || tabOption,
      {
        members: data,
      },
      setState,
    )(dispatch);
  };

  const onDrop = useCallback(async ([file]) => {
    const supportedExtensions = ['xls', 'xlsx'];
    const format = file.path.split('.').pop();

    if (!supportedExtensions.includes(format.toLowerCase())) {
      setError({ message: `Format not supported *.${format}` });
      setState('error');
      return;
    }

    try {
      const sheets = await readXlsxFile(file, { getSheets: true });

      setState('progress');
      const uploadedDataPromisses = sheets.map(
        async ({ name }, index) => {
          const sheetData = await readXlsxFile(file, {
            sheet: index + 1,
            schema: sheetSchemas[name],
          });
          return {
            name,
            data: sheetData,
          };
        },
      );

      const uploadedData = await Promise.all(uploadedDataPromisses);

      const hasErrors = uploadedData.find(({ data, name }) => {
        if (!data.errors) {
          throw new Error(
            `Sheet with the name of "${name}" is not allowed`,
          );
        }
        return data.errors.length > 0;
      });

      if (hasErrors) {
        const { name, data } = hasErrors;
        const { errors, rows } = data;
        const firstError = errors[0];
        const message = `Sheet: ${name}, Column: ${
          firstError.column
        }, Row number ${firstError.row + 1} is/has ${
          firstError.error
        }`;
        setState('error');
        setError({
          downloadable: false,
          message,
          errors: [
            {
              message,
              data: JSON.stringify(
                rows[firstError.row - 1],
                undefined,
                2,
              ),
            },
          ],
        });
        return;
      }

      const formattedData = formatBulkData(
        uploadedData,
        projectId || tabOption,
      );

      const extraValidations = validateBulkData(
        uploadedData,
        formattedData,
        uploadLastNumber,
      );

      const hasExtraErrors = some(
        extraValidations,
        fileValidation => !fileValidation.isValid,
      );

      if (hasExtraErrors) {
        const message = reduce(
          extraValidations,
          (message, currentValue, key) => {
            if (!currentValue.isValid)
              return `${message} \n  File: ${capitalize(key)}::: ${
                currentValue.message
              } __sp`;

            return message;
          },
          '',
        );
        throw new Error(message);
      }

      handleSubmit(formattedData);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      setState('error');
      const message =
        'A validation error occured before sending the file please check the errors below.';
      setError({
        downloadable: false,
        message,
        errors: [{ message: error?.message }],
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <section className="bulk-create">
      <Message info size="tiny" compact>
        <List relaxed>
          <List.Item
            icon="info circle"
            content={
              <h4>
                {uploadLastNumber
                  ? `The last group upload number is `
                  : ` No group upload number yet :) you can start from `}
                <Label size="small">
                  {Number(uploadLastNumber || 1)}
                </Label>
              </h4>
            }
          />
        </List>
      </Message>
      <p>
        Download Data Collection Form
        <a
          href={DATA_COLLECTION_TEMPLATE}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          {' '}
          here
        </a>
      </p>
      <p>
        Download Data Compilation Sheet
        <a
          href={DATA_COMPILATION_TEMPLATE}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          {' '}
          here
        </a>
      </p>

      <div className="drag-box" {...getRootProps()}>
        <img src={folderImage} alt="" className="file" />

        <input {...getInputProps()} />

        {!isDragActive && (
          <p>
            Drag and Drop the Filled Data Compilation Sheet here or
            <span className="link"> browse</span>
          </p>
        )}

        {isDragActive && <p>Drop the file here..</p>}

        <div className="format">
          <span>Supported format:</span>
          <div>
            <img src={xlsFileExtension} alt="" />
            <img src={xlsxFileExtension} alt="" />
          </div>
        </div>
      </div>

      {state === 'progress' && (
        <div className="loading-box">
          <UploadProgress setState={setState} />
        </div>
      )}
      {state === 'success' && <UploadSuccess data={data || {}} />}
      {state === 'error' && (
        <UploadError error={error || apiError} setState={setState} />
      )}
    </section>
  );
};

UploadError.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};

UploadSuccess.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BulkCreate;

// TODO: bulk upload front
// - add latest upload number ✅
// - validation
// *- groups ( if group number is required, it doesnt have duplicate and greater than the last)
// *- member (if phone number are not duplicate in same group  )
// *- agents (if phone number are not duplicate in same group  )
// - add a summary for skipped ( not created )
