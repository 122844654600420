import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Message,
  Input,
  Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import isValidId from 'helpers/validators/isValidId';
import { canPerformAction } from 'helpers/permissions';
import './SingleUser.scss';
import {
  isOrganizationAdmin,
  isSupportAdmin,
} from 'helpers/checkRoles';
import permissions from 'constants/permissions';
import getUserRoles from 'helpers/getUserRoles';
import nidaVerification from 'helpers/nidaVerification';
import shouldUseNIDA from 'helpers/shouldUseNIDA';

const SingleUserForm = ({
  user,
  form,
  formData,
  genderOptions,
  idTypeOptions,
  maritalStatusOptions,
  levelOfEducationOptions,
  loading,
  error,
  handleChange,
  handleSubmit,
  isSaveUser,
  openPopup,
  openInfluencerModal,
  setForm,
}) => {
  const { currentOrgId, orgUsers, roles } = getUserRoles();
  const [nidaData, setNidaData] = useState({});
  const [fetchNidaLoading, setFetchNidaLoading] = useState(false);
  const [nidaVerificationData, setNidaVerificationData] = useState(
    {},
  );
  const [verifyNidaLoading, setVerifyNidaLoading] = useState(false);

  const supportAdminAccess = () => {
    return (
      roles &&
      isSupportAdmin(roles) &&
      isOrganizationAdmin(currentOrgId, orgUsers)
    );
  };

  const fetchIdNumber = async nationalId => {
    setFetchNidaLoading(true);

    const res = await nidaVerification('fetch', nationalId);

    setFetchNidaLoading(false);
    setNidaData(res);
    setForm({
      ...form,
      first_name: res.foreName,
      last_name: res.surnames,
    });
  };

  const verifyIdNumber = async (nationalId, data) => {
    setVerifyNidaLoading(true);

    const res = await nidaVerification('verify', nationalId, data);
    setVerifyNidaLoading(false);
    setNidaVerificationData(res);
  };

  useEffect(() => {
    if (
      isValidId(form.id_number) &&
      shouldUseNIDA(form.id_type || user.id_type)
    ) {
      fetchIdNumber(form.id_number);
    }
  }, [form.id_number, form.id_type]);

  useEffect(() => {
    if (
      isValidId(form.id_number) &&
      !nidaData.fail &&
      form.birth_date &&
      form.gender &&
      form.marital_status &&
      shouldUseNIDA(form.id_type || user.id_type)
    ) {
      verifyIdNumber(form.id_number, {
        date_of_birth: form.birth_date,
        gender: form.gender === 'male' ? 'M' : 'F',
        marital_status: form.marital_status,
      });
    }
  }, [form.birth_date, form.gender, form.marital_status]);

  return (
    <Form loading={loading}>
      <Form.Group widths="equal">
        <Form.Dropdown
          placeholder="Select"
          fluid
          label="ID Type"
          selection
          value={form.id_type || user.id_type}
          options={idTypeOptions}
          name="id_type"
          onChange={handleChange}
          disabled={!supportAdminAccess}
        />
        <Form.Input
          control={Input}
          onChange={handleChange}
          label="ID Number"
          name="id_number"
          placeholder="ID Number"
          defaultValue={form.id_number}
          loading={fetchNidaLoading}
          error={
            form.id_number && !isValidId(form.id_number)
              ? { content: 'Wrong ID Number' }
              : nidaData.fail
              ? { content: 'Invalid national ID' }
              : false
          }
          icon={
            form.id_number && isValidId(form.id_number)
              ? 'checkmark'
              : ''
          }
          disabled={!supportAdminAccess}
        />
      </Form.Group>
      <Form.Input
        onChange={handleChange}
        required
        label="Names"
        name="first_name"
        placeholder="Names"
        value={
          !nidaData.fail
            ? `${nidaData.foreName || ''}  ${nidaData.surnames || ''}`
            : ''
        }
        defaultValue={
          !nidaData.fail
            ? `${nidaData.foreName || ''}  ${nidaData.surnames || ''}`
            : ''
        }
        disabled
      />
      <Form.Group widths="equal">
        <Form.Input
          onChange={handleChange}
          fluid
          label="Birth Day"
          placeholder="Birth Day"
          type="date"
          name="birth_date"
          loading={form?.birth_date && verifyNidaLoading}
          value={
            form.birth_date
              ? moment(form.birth_date).format('YYYY-MM-DD')
              : ''
          }
          error={
            form.birth_date &&
            !nidaVerificationData?.date_of_birth?.isValid
              ? { content: 'Wrong birth date' }
              : false
          }
          disabled={!supportAdminAccess}
        />
        <Form.Dropdown
          placeholder="Gender"
          fluid
          label="Gender"
          selection
          value={form.gender || user.gender}
          options={genderOptions}
          loading={form?.gender && verifyNidaLoading}
          error={
            form.gender && !nidaVerificationData?.gender?.isValid
              ? { content: 'Wrong gender' }
              : false
          }
          name="gender"
          onChange={handleChange}
          disabled={!supportAdminAccess}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Dropdown
          placeholder="Marital Status"
          fluid
          label="Marital Status"
          selection
          value={form.marital_status || user.marital_status}
          options={maritalStatusOptions}
          name="marital_status"
          loading={form?.marital_status && verifyNidaLoading}
          error={
            form.marital_status &&
            !nidaVerificationData?.marital_status?.isValid
              ? { content: 'Wrong marital status' }
              : false
          }
          onChange={handleChange}
          disabled={!supportAdminAccess}
        />
        <Form.Input
          placeholder="Occupation"
          label="Occupation"
          value={form.occupation || user.occupation}
          name="occupation"
          onChange={handleChange}
          disabled={!supportAdminAccess}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          disabled
          label="Phone"
          placeholder="Phone"
          type="text"
          name="phone_number"
          className="phone-number-field"
          action={{
            icon: 'pencil',
            color: 'blue',
            disabled:
              !canPerformAction('updateUser', roles, {
                currentOrgId,
                orgUsers,
              }) || !isSaveUser,
            className: 'phone_number',
            onClick: openPopup,
          }}
          defaultValue={formData.phone_number}
        />
        <Form.Dropdown
          placeholder="Level Of Education"
          fluid
          label="Level Of Education"
          selection
          value={form.education_level || user.education_level}
          options={levelOfEducationOptions}
          name="education_level"
          onChange={handleChange}
          disabled={!supportAdminAccess}
        />
      </Form.Group>
      <Form.Field>
        <Form.Input
          onChange={handleChange}
          label="Personal E-mail"
          placeholder="Personal E-mail"
          type="email"
          name="email"
          value={form.email}
          disabled={
            !canPerformAction('updateUser', roles, {
              currentOrgId,
              orgUsers,
            })
          }
        />
      </Form.Field>

      <div className="influencer-field">
        <p>Influencer</p>
        <div className="right">
          <Checkbox
            toggle
            checked={formData.referral_user_type === 'influencer'}
            name="influencer_alias"
            disabled={
              !canPerformAction(
                permissions.canPerformAction.assignInfluencerAlias,
                roles,
                {
                  currentOrgId,
                  orgUsers,
                },
              )
            }
            onClick={() =>
              canPerformAction(
                permissions.canPerformAction.assignInfluencerAlias,
                roles,
                {
                  currentOrgId,
                  orgUsers,
                },
              ) && openInfluencerModal()
            }
          />
        </div>
      </div>

      {formData.referral_user_type === 'influencer' && (
        <Form.Field>
          <Form.Input
            label="Alias Name(optional)"
            name="referral_alias"
            placeholder="Alias name"
            value={formData.referral_alias}
            disabled
          />
        </Form.Field>
      )}
      <h3> Professional Details </h3>
      <Form.Group widths="equal">
        <Form.Input
          onChange={handleChange}
          fluid
          required={!isSaveUser}
          label="Work E-mail"
          placeholder="Work E-mail"
          type="email"
          name="work_email"
          value={form.work_email}
          disabled={
            !canPerformAction('updateUser', roles, {
              currentOrgId,
              orgUsers,
            })
          }
        />
        <Form.Input
          placeholder="Position"
          fluid
          label="Position"
          name="position"
          value={form.position || user.position}
          onChange={handleChange}
          disabled={
            !canPerformAction('updateUser', roles, {
              currentOrgId,
              orgUsers,
            })
          }
        />
      </Form.Group>
      {error && <Message negative> {error.message} </Message>}
      <hr />
      <div className="submit-btn-wrapper">
        <Button
          type="submit"
          primary
          onClick={handleSubmit}
          disabled={
            (form.id_number && !isValidId(form.id_number)) ||
            !form.first_name ||
            !form.last_name ||
            !form.id_type ||
            !nidaVerificationData?.marital_status?.isValid ||
            !nidaVerificationData?.date_of_birth?.isValid ||
            !nidaVerificationData?.gender?.isValid ||
            nidaData.fail
          }
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

SingleUserForm.defaultProps = {
  isSaveUser: false,
  openPopup: () => null,
  openInfluencerModal: () => null,
  setForm: () => null,
};

SingleUserForm.propTypes = {
  user: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired,
  formData: PropTypes.objectOf(Object).isRequired,
  genderOptions: PropTypes.arrayOf(Object).isRequired,
  idTypeOptions: PropTypes.arrayOf(Object).isRequired,
  openInfluencerModal: PropTypes.func,
  maritalStatusOptions: PropTypes.arrayOf(Object).isRequired,
  levelOfEducationOptions: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.objectOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isSaveUser: PropTypes.bool,
  openPopup: PropTypes.func,
  setForm: PropTypes.func,
};

export default SingleUserForm;
