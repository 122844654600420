// @ts-check

import {
  chain,
  mapValues,
  reduce,
  groupBy,
  isEmpty,
  keys,
  pickBy,
  map,
} from 'lodash';

import getDuplicateInArray from './getDuplicateInArray';

/**
 * @brief
 * @author Hadad
 * @typedef {{ isValid: boolean, message?: string, reference?: {}}} returnType
 * @param {("members" | "groups" | "agents")} context
 * @param {Array.<Object>} entity
 * @returns {returnType}
 */
function checkForDuplicates(context, entity) {
  if (context === 'groups') {
    const groupByIds = groupBy(entity, 'id');
    const duplicateRows = pickBy(
      groupByIds,
      element => element.length > 1,
    );

    if (!isEmpty(duplicateRows)) {
      return {
        isValid: false,
        message: `The following ids are duplicated ${keys(
          duplicateRows,
        ).join(', ')}`,
        reference: duplicateRows,
      };
    }
  }

  if (context === 'members') {
    const groupedMembersByGroups = groupBy(
      entity,
      'group.group_number',
    );

    // this might seems confusing but we are grouping member phone by group id the shape is { '2': ['078xx', '078xx'], '3': ['078xx', '078xx'] }
    const groupedMembersWithPhoneNumbers = mapValues(
      groupedMembersByGroups,
      groupedMembers => map(groupedMembers, 'phone_number'),
    );

    // now we find duplicates if any
    const duplicates = chain(groupedMembersWithPhoneNumbers)
      .mapValues(getDuplicateInArray)
      .omitBy(isEmpty)
      .value();

    if (!isEmpty(duplicates)) {
      const message = reduce(
        duplicates,
        (finalMessage, value, key) =>
          `
          ${finalMessage}
          __sub_sp
          Group ${key}: ${JSON.stringify(value)}`, // __sub_sp will be used to split and show the message nicely
        '',
      );

      return {
        isValid: false,
        message: `The following phone numbers are duplicated for groups ${keys(
          duplicates,
        )} 
        ${message}`,
        reference: duplicates,
      };
    }
  }

  if (context === 'agents') {
    const groupIds = map(entity, 'id');
    const duplicate = getDuplicateInArray(groupIds);
    if (!isEmpty(duplicate)) {
      return {
        isValid: false,
        message: `The following ids are duplicated ${duplicate.join(
          ', ',
        )}`,
        reference: duplicate,
      };
    }
  }

  return { isValid: true, message: '', reference: [] };
}

function checkLastUploadNumber(entity, lastUploadNumber) {
  const hasOldUploadNumber = map(entity, 'id').filter(
    currentNumber => Number(currentNumber) < Number(lastUploadNumber),
  );

  if (!isEmpty(hasOldUploadNumber)) {
    return {
      isValid: false,
      message: `All ids should be greater than ${Number(
        lastUploadNumber,
      )}`,
      reference: hasOldUploadNumber,
    };
  }
}

/**
 * @brief
 * @param {("members" | "groups" | "agents")} context
 * @param {Array.<Object>} entity
 * @param {string} [lastUploadNumber] - optional param only used fo groups
 * @returns {returnType}
 */
function validateFileBulkData(context, entity, lastUploadNumber) {
  if (context === 'groups') {
    // checkLastUploadNumber(entity, lastUploadNumber);
    return checkForDuplicates(context, entity);
  }

  if (context === 'members') {
    return checkForDuplicates(context, entity);
  }

  if (context === 'agents') {
    return checkForDuplicates(context, entity);
  }

  return { isValid: true, message: '', reference: [] };
}

/**
 * @brief
 * @param {Array.<Object>} unformattedData
 * @param {Array.<Object>} formattedData
 * @param {string} lastUploadNumber
 * @returns {Object}
 */
export default function validateBulkData(
  unformattedData,
  formattedData,
  lastUploadNumber,
) {
  /** @type {Array.<"groups" | "agents" | "members">} */
  const context = ['groups', 'members', 'agents'];

  const validationStates = context.reduce(
    (allState, currentContext) => {
      if (currentContext === 'groups') {
        const entities = unformattedData.find(sheet =>
          sheet?.name?.includes('SGs'),
        )?.data?.rows;

        return {
          ...allState,
          [currentContext]: validateFileBulkData(
            currentContext,
            entities,
            lastUploadNumber,
          ),
        };
      }

      if (currentContext === 'members')
        return {
          ...allState,
          [currentContext]: validateFileBulkData(
            currentContext,
            formattedData,
          ),
        };

      if (currentContext === 'agents') {
        const entities = unformattedData.find(sheet =>
          sheet?.name?.includes('VAs'),
        )?.data?.rows;

        return {
          ...allState,
          [currentContext]: validateFileBulkData(
            currentContext,
            entities,
          ),
        };
      }

      return allState;
    },
    {},
  );

  return validationStates;
}
